<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-card flat class="card-datatable">
      <v-card-text>
        <v-row>
          <v-col cols="6" md="3" class="pl-3" style="margin-left: 5px">
            <v-autocomplete
              label="Filter By Year"
              outlined
              dense
              v-model="yearFilter"
              :items="yearList"
              class="formFields"
              color="#7253CF"
              :disabled="loading"
              @change="getTeacherData()"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2" v-if="$vuetify.breakpoint.xsOnly">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group
                  active-class="active-class"
                  v-if="
                    this.adminAccess != 'game' || this.adminAccess != 'school'
                  "
                >
                  <v-list-item @click="exportTeacher">
                    <v-list-item-title> Export </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group
                  active-class="active-class"
                  v-if="
                    layout === 'CampaignDashboardLayout' &&
                    this.$route.matched[0].path.substring(1) === 'school' &&
                    (this.adminAccess == 'Super Admin' ||
                      this.adminAccess == 'Sales Representative' ||
                      this.adminAccess == 'Event Support')
                  "
                >
                  <v-list-item @click="route">
                    <v-list-item-title> bulk import </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group
                  active-class="active-class"
                  v-if="
                    layout === 'CampaignDashboardLayout' &&
                    this.$route.matched[0].path.substring(1) === 'school' &&
                    this.adminAccess != 'school'
                  "
                >
                  <v-list-item
                    @click="
                      toggleAddEditViewTeacherModal({ show: true, type: 'add' })
                    "
                  >
                    <v-list-item-title> Add Teacher </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="11" md="4">
            <v-text-field
              outlined
              dense
              v-model="teacherDataTable[$API_KEYS.SEARCH]"
              label="Search by Teacher Name, Email or School"
              append-icon="mdi-magnify"
              type="text"
              color="#38227A"
              class="search-input"
              height="43px"
              style="margin: 5px"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="8"
            class="text-right"
            v-if="
              (this.adminAccess != 'game' || this.adminAccess != 'school') &&
              $vuetify.breakpoint.smAndUp
            "
          >
            <v-btn
              text
              style="
                border: 1px solid #38227a;
                border-radius: 10px;
                margin: 5px;
              "
              class="text-capitalize mr-5 px-12"
              height="45px"
              width=" 2%"
              @click="exportTeacher"
              :loading="btnLoading"
              v-if="
                layout === 'CampaignDashboardLayout' &&
                this.adminAccess != 'school'
              "
            >
              <span style="color: #38227a">Export</span>
            </v-btn>
            <v-btn
              text
              style="
                border: 1px solid #38227a;
                border-radius: 10px;
                margin: 5px;
              "
              class="text-capitalize mr-5 px-12"
              height="45px"
              width=" 2%"
              @click="route"
              v-if="
                layout === 'CampaignDashboardLayout' &&
                this.$route.matched[0].path.substring(1) === 'school' &&
                (this.adminAccess == 'Super Admin' ||
                  this.adminAccess == 'Sales Representative' ||
                  this.adminAccess == 'Event Support')
              "
            >
              <span style="color: #38227a">bulk import</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              @click="
                toggleAddEditViewTeacherModal({ show: true, type: 'add' })
              "
              class="px-12 text-capitalize"
              height="45px"
              width=" 10%"
              style="border-radius: 10px; margin-right: 25px"
              v-if="
                layout === 'CampaignDashboardLayout' &&
                this.$route.matched[0].path.substring(1) === 'school' &&
                this.adminAccess != 'school'
              "
            >
              <span>Add Teacher</span>
            </v-btn>
            <!--   <v-btn
              color="#38227A"
              dark
              class="px-12 text-capitalize"
              height="45px"
               width="5%"
              @click="route1()"
              style="border-radius: 10px">
              <span>Upload</span>
            </v-btn>-->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="table-div">
      <v-data-table
        :options.sync="dataOptions"
        fixed-header
        hide-default-footer
        :headers="teacherDataTable.headers"
        :items="teacherDataTable.items"
        :loading="teacherDataTable.loading"
        :items-per-page="teacherDataTable.page_info.detail_count"
        :server-items-length="teacherDataTable.page_info.detail_count"
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item="row">
          <tr class="text-flex" style="font-size: 14px">
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600">{{ row.item.teacher_name }}</div>
              <div style="font-weight: 600; font-size: 13px; color: #777777">
                {{ row.item.teacher_email }}
              </div>
              <div v-if="row.item.last_login" class="justify-center d-flex">
                <div style="font-weight: 500; font-size: 11px; color: #777777">
                  Last Login
                </div>
                <div
                  style="
                    font-weight: 500;
                    font-size: 11px;
                    color: #757575;
                    margin-left: 2px;
                  "
                >
                  {{ row.item.last_login.date }}, {{ row.item.last_login.time }}
                </div>
              </div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600; color: #1d1d1d">
                {{ row.item.campaign_id }}
              </div>
              <div style="color: #777777">{{ row.item.campaign_school }}</div>
              <div style="color: #777777">{{ row.item.campaign_district }}</div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600; color: #1d1d1d">
                {{ row.item.display_name }}
              </div>
              <div style="color: #777777">{{ row.item.grade_title }}</div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600">
                ${{ row.item.original_total_fund_raised }}/<span
                  style="color: #777777; font-weight: 400"
                  >${{ row.item.teacher_goal }}</span
                >
              </div>
              <div style="color: #067605; weight: 700">
                {{ row.item.teacher_fund_raised_percentage }}%
              </div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weight: 600">
                ${{ row.item.original_class_fund_raised }}/<span
                  style="color: #777777; font-weight: 400"
                  >${{ row.item.class_goal }}</span
                >
              </div>
              <div style="color: #067605; weight: 700">
                {{ row.item.class_fund_raised_percentage }}%
              </div>
            </td>
            <td class="text-center row-item py-4 px-0">
              <div style="font-weigh: 600">{{ row.item.total_shares }}</div>
            </td>
            <td class="text-center row-item py-4">
              <div style="font-weigh: 600">{{ row.item.total_students }}</div>
            </td>
            <td class="text-center row-item py-4">
              <v-row class="justify-center">
                <v-col class="pa-0 text-center" cols="12" xl="8">
                  <v-chip
                    @click="viewPublicDonationPageHandler(row.item)"
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-open-in-new
                        </v-icon>
                      </template>
                      <span>Public Page</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    class="actionColumn-btn"
                    color="#F2F2F2"
                    @click="visitTeacherCampaign(row.item)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-eye
                        </v-icon>
                      </template>
                      <span>Dashboard</span>
                    </v-tooltip>
                  </v-chip>

                  <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        color="#F2F2F2"
                        class="actionColumn-btn"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="#6B6B6B" size="25"
                          >mdi-dots-horizontal</v-icon
                        >
                      </v-chip>
                    </template>
                    <v-list class="menu-list py-0">
                      <v-list-item-group class="py-0">
                        <v-list-item
                          @click="
                            toggleAddEditViewTeacherModal({
                              show: true,
                              type: 'edit',
                              id: row.item.id,
                            })
                          "
                          style="min-height: 40px"
                        >
                          <v-icon left small>mdi-pencil</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Edit
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                          @click="
                            toggleDeleteTeacherModal({
                              Delete: true,
                              id: row.item.id,
                            })
                          "
                        >
                          <v-icon left small>mdi-delete</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Delete
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                          @click="resendMail(row.item.id)"
                        >
                          <v-icon left small>mdi-email-send</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Resend Invitation
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>

                  <!--  <v-chip
                    class="actionColumn-btn"
                    color="#F2F2F2"
                    @click="
                      toggleAddEditViewTeacherModal({
                        show: true,
                        type: 'edit',
                        id: row.item.id,
                      })
                    "
                    v-if="$route.matched[0].path.substring(1) !== 'school'"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    @click="
                      toggleDeleteTeacherModal({
                        Delete: true,
                        id: row.item.id,
                      })
                    "
                    class="actionColumn-btn"
                    color="#F2F2F2"
                    v-if="$route.matched[0].path.substring(1) !== 'school'"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-chip>
                  -->
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="teacherDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="justify-center justify-md-start d-flex px-5 tableHeader-text"
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPage"
                      hide-details
                      v-model="teacherDataTable.page_info.detail_count"
                      dense
                    ></v-select>
                  </div>

                  <div class="alignSelf-center">
                    {{
                      teacherDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      teacherDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      teacherDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      teacherDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="teacherDataTable.items[0] && $vuetify.breakpoint.xsOnly"
      flat
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    teacherDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  teacherDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  teacherDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  teacherDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  teacherDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <add-edit-view-teacher-modal
      @reload="getTeacherData()"
    ></add-edit-view-teacher-modal>
    <delete-teacher-modal @reload="getTeacherData()"></delete-teacher-modal>
  </div>
</template>
<script>
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  DATA,
  SORTING_KEY,
  TEACHER_LIST,
  CAMPAIGN_ID,
} from "@/constants/APIKeys";
import authToken from "@/common/authToken";
import {
  ITEMS,
  LOADING,
  TEACHER,
  SCHOOL,
  PRINCIPAL,
} from "@/constants/ModuleKeys";
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_ADMIN_TEACHER_MANAGEMENT_LIST,
  RESEND_INVITE_MAIL,
  API_EXPORT_TEACHER,
  API_GET_FISCAL_YEAR_RANGE,
} from "@/constants/APIUrls";
import _ from "lodash";
import { ROUTER_URL } from "@/constants/urls";
import Helper from "@/helper";
export default {
  name: "TeacherManagement",
  components: {
    AddEditViewTeacherModal: () =>
      import("./AddEditViewTeacherModal/AddEditTeacherModal.vue"),
    DeleteTeacherModal: () =>
      import("./DeleteTeacherConfirmation/DeleteTeacherConfirmation.vue"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      yearList: [],
      yearFilter: "",
      dataOptions: { sortBy: [], sortDesc: [] },
      teacherDataTable: {
        loading: false,
        headers: [
          {
            sortable: true,
            text: "Teacher",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg px-0"],
            value: "display_name",
          },
          {
            sortable: true,
            text: "Campaign",
            align: "center",
            value: "campaign",
            class: ["tableHeader-text", "tableHeader-bg px-0"],
          },
          {
            sortable: true,
            text: "Class",
            align: "center",
            value: "grade",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: true,
            text: "Teacher Fund Raised",
            align: "center",
            value: "total_fund_raised",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: true,
            text: "Class Fund Raised",
            value: "class_fund_raised",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: true,
            text: "# of Shares  ",
            value: "total_shares",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            sortable: true,
            text: "# of Student ",
            value: "total_students_registered",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "16%",
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: false,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
      btnLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "teacherDataTable.page_info.page_number": function () {
      this.debounceGetTeacherManagementData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "teacherDataTable.page_info.detail_count": function () {
      console.log(this.teacherDataTable.page_info.detail_count, "page issue");
      this.getTeacherData();
    },
    //Watcher for detecting change in SEARCH
    "teacherDataTable.search": function () {
      this.debounceGetTeacherManagementData();
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.teacherDataTable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getTeacherData();
      } else {
        this.teacherDataTable[SORTING_KEY] = "";
        this.getTeacherData();
      }
    },
  },
  methods: {
    ...mapActions({
      // teacher Modal
      toggleAddEditViewTeacherModal: "teacherManagement/toggleModal",
      toggleDeleteTeacherModal: "teacherManagement/toggleDeleteModal",
      showToast: "snackBar/showToast",
    }),
    route() {
      this.$router.push({
        name: ROUTER_URL.schoolPanel.children.bulkUploadTeacher.name,
        query: {
          [SCHOOL]: this.selectedCampaignData.organizationData.id,
          [CAMPAIGN_ID]: this.selectedCampaignData.campaignData.id,
        },
      });
      // this.$root.$refs.campaignLayout.route()
    },
    getTeacherData() {
      const self = this;
      self.teacherDataTable.loading = true;
      console.log("in get teacher data");

      const successHandler = (res) => {
        console.log(
          "ITEMS_PER_PAGE: ",
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE],
          "PAGE_NUMBER: ",
          res[DATA][PAGE_INFO][PAGE_NUMBER],
          "TOTAL_PAGE: ",
          res[DATA][PAGE_INFO][TOTAL_PAGE],
          "TEACHER_LIST: ",
          res[DATA][TEACHER_LIST]
        );
        self.teacherDataTable.items = res[DATA][TEACHER_LIST];
        self.teacherDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.teacherDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.teacherDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        self.teacherDataTable.loading = false;
        console.log(self.teacherDataTable.items, "reg");
      };
      const failureHandler = (res) => {
        console.log(res, "failed");
        self.teacherDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SEARCH] = this.teacherDataTable[SEARCH];
      if (this.teacherDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.teacherDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.teacherDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.teacherDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      dataTableParams[SORTING_KEY] = this.teacherDataTable[SORTING_KEY];
      if (this.yearFilter) {
        dataTableParams.fiscal_year = this.yearFilter;
      }
      // if (this.layout === "CampaignDashboardLayout") {
      //   if (this.userTeam === "district" || this.userTeam === "school") {
      //     dataTableParams["campaign_id"] =
      //       this.selectedCampaignData.campaignData.id;
      //   }
      //   if (this.userTeam === "admin") {
      //     dataTableParams["campaign_id"] =
      //       this.selectedCampaignData.campaignData.id;
      //     if (this.$route.matched[0].path.substring(1) === "district") {
      //       dataTableParams["district_id"] =
      //         this.selectedCampaignData.organizationData.id;
      //     } else {
      //       dataTableParams["school_id"] =
      //         this.selectedCampaignData.school.organizationData.id;
      //     }
      //   }
      // }
      let PANEL = this.$route.matched[0].path.substring(1);
      if (this.layout === "CampaignDashboardLayout") {
        if (PANEL === "district" && this.userTeam === "district") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
        } else if (PANEL === "school" && this.userTeam === "school") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
        } else if (
          PANEL === "district" &&
          (this.userTeam === "admin" || this.userTeam === "game")
        ) {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          dataTableParams["district_id"] =
            this.selectedCampaignData.organizationData.id;
        } else if (
          PANEL === "school" &&
          (this.userTeam === "admin" ||
            this.userTeam === "district" ||
            this.userTeam === "game")
        ) {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          dataTableParams["school_id"] =
            this.selectedCampaignData.organizationData.id;
        }
      }

      Axios.request_GET(
        API_ADMIN_TEACHER_MANAGEMENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    visitTeacherCampaign(teacher) {
      console.log(teacher);
      const { id, real_campaign_id } = teacher;
      console.log(real_campaign_id, "campa");
      if (teacher.teacher_type === "general") {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.teacherPanel.children.dashboard.name,
          query: { [CAMPAIGN_ID]: real_campaign_id, [TEACHER]: id },
        });
        window.open(routeData.href, "_blank");
      } else if (teacher.teacher_type === PRINCIPAL) {
        console.log(real_campaign_id);
        let routeData = this.$router.resolve({
          name: ROUTER_URL.principalPanel.children.dashboard.name,
          query: { [CAMPAIGN_ID]: real_campaign_id, [TEACHER]: id },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.peteacherPanel.children.dashboard.name,
          query: { [CAMPAIGN_ID]: real_campaign_id, [TEACHER]: id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    viewPublicDonationPageHandler(item) {
      const { id, sub_domain } = item;
      const queryParam = "teacher=" + id;
      Helper.openPublicDonationPage(sub_domain, queryParam);
    },
    resendMail(id) {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Invitation email resent successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Something Went Wrong",
          color: "e",
        });
      };
      let formData = {};
      formData["user_id"] = id;
      formData["user_type"] = "teacher";
      Axios.request_GET(
        RESEND_INVITE_MAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    exportTeacher() {
      this.btnLoading = true;
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Teacher.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.btnLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.btnLoading = false;
      };
      let formData = {};
      formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
      let PANEL = this.$route.matched[0].path.substring(1);
      if (PANEL === "district") {
        formData["district_id"] = this.selectedCampaignData.organizationData.id;
      } else {
        formData["school_id"] = this.selectedCampaignData.organizationData.id;
      }
      Axios.request_GET_BLOB(
        API_EXPORT_TEACHER,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
    getYearList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.yearList = data.year_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_GET_FISCAL_YEAR_RANGE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
    route1() {
      this.$router.push({
        name: ROUTER_URL.schoolPanel.children.resourceupload.name,
      });
      //this.$root.$refs.adminLayout.route("school");
    },
  },
  created() {
    this.$root.$refs.teacherManagemnet = this;
    this.debounceGetTeacherManagementData = _.debounce(this.getTeacherData, 50);
  },
  mounted() {
    //alert(this.adminAccess)
    this.getTeacherData();
    this.getYearList();
  },
};
</script>
<style scoped>
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.footer-card.v-card {
  max-height: 80px;
  font-family: Lato;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.alignSelf-center {
  align-self: center;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.card-datatable.v-card {
  max-height: 150px;
  margin-bottom: -2px;
  display: flex;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu {
  position: relative;
  bottom: 12px;
  right: -7px;
}
.text-capitalize.v-btn >>> span {
  letter-spacing: 0px;
  font-family: Lato;
  font-weight: 600;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  border-radius: 0px;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    min-width: 1200px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tableHeader-text {
    font-size: 12px !important;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .table-div {
    /**  margin-top: 189px; */
  }
}
</style>
